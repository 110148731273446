import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import get from "lodash/get"
import styled from "styled-components"
import Block from "../components/Block"
import Header from "../components/Header"
import AppBlock from "../components/AppBlock"
import { gutter, active } from "../vars"
import FaqItem from "../components/FaqItem"
import Seo from "../components/Seo"
import { useSettingContext } from "../settingsContext"
import Cookies from "js-cookie"
import isMobile from 'ismobilejs';
import CitySelector from "../components/CitySelector";

const Page = styled.div``

const Item = styled.div`
  margin-bottom: ${gutter * 4}px;
  padding-bottom: ${gutter * 4}px;
  border-bottom: 1px solid white;
`
const Top = styled.div`
  display: flex;
  margin-bottom: ${gutter * 3}px;
  font-size: 22px;
`
const City = styled.div`
  cursor: pointer;
  color: ${p => (p.selected ? active : "white")};
  transition: color 0.3s ease;
  margin-right: ${gutter * 2}px;
  position: relative;
  font-weight: ${p => (p.selected ? "bold" : "normal")};

  &:after {
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    display: block;
    bottom: -${gutter / 2}px;
    background: ${active};
    content: "";
    display: ${p => (p.selected ? "block" : "none")};
  }
`
const Label = styled.div`
  font-weight: bold;
  margin-right: ${gutter * 2}px;
`
const Wrapper = styled.div`
  max-width: 650px;
  margin: 0 auto;
`

export default ({ data }) => {
  const nodes = get(data, "allPrismicFaq.nodes", [])
  const cities = get(data, "allPrismicCity.nodes", []).sort((a, b) =>
    a.data.name.text.localeCompare(b.data.name.text)
  )
  const [selected, setSelected] = useState(cities[0])

  const restrictionId = selected.prismicId
  const selectedNodes = nodes.filter(node =>
    node.data.restriction ? node.data.restriction.id === restrictionId : true
  )
  const onSelect = node => {
    setSelected(node)
    Cookies.set("selectedCity", node.id)
  }

  const DesktopTop = () => {
    return cities.map((c, i) => (
      <City
        selected={selected.id === c.id}
        onClick={() => onSelect(c)}
        key={i}
      >
        {c.data.name.text}
      </City>
    ))
  }

  const MobileTop = () => {
    return <CitySelector nodes={cities} onSelect={onSelect} selected={selected} />
  }


  useEffect(() => {
    const cachedCityValue = Cookies.get("selectedCity")
    const cachedCity = cities.find(node => node.id == cachedCityValue)
    if (cachedCity) onSelect(cachedCity)
  }, [])

  const t = useSettingContext()

  return (
    <Page>
      <Wrapper>
        <Top>
          <Label>{t("select_city")}: </Label>
          {isMobile().any ? <MobileTop /> : <DesktopTop />}
        </Top>
        {selectedNodes.map((node, i) => (
          <FaqItem data={node.data} key={i} />
        ))}
      </Wrapper>
      <Seo />
    </Page>
  )
}

export const query = graphql`
  query($locale: String) {
    allPrismicCity(filter: { lang: { eq: $locale } }) {
      nodes {
        lang
        id
        prismicId
        data {
          name {
            text
          }
        }
      }
    }

    allPrismicFaq(
      filter: { lang: { eq: $locale } }
      sort: { order: ASC, fields: data___order }
    ) {
      nodes {
        data {
          restriction {
            id
          }

          question {
            html
          }
          anchorlink
          is_prio
          answer_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1140, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            url
            alt
          }

          answer {
            html
          }
        }
      }
    }
  }
`
