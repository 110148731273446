import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { gutter, primaryBg } from "../vars"
import Image from "./Image"
import once from "lodash/once"

const Container = styled.div`
  margin: 0 auto ${gutter * 5}px auto;

  margin-bottom: ${gutter * 2}px;

  ol,
  ul {
    margin: 0;
  }
`
const Question = styled.div`
  p {
    margin-top: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const Answer = styled.div`
  p {
    margin: 0;
    padding-bottom: 1em;

    &:last-child {
      padding-bottom: 0;
    }
  }
`
const Content = styled.div`
  position: absolute;
  overflow: hidden;
  transition: height 0.3s ease, opacity 0.3s ease;
  pointer-events: ${p => (p.open ? "all" : "none")};
  opacity: ${p => (p.isPrio ? 1 : 0)};
  position: ${p => (p.isPrio ? "relative" : "absolute")};

  img {
    width: 100%;
  }
`

const icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
    <path fill="none" d="M0 0h24v24H0V0z" />
  </svg>
)

const Header = styled.div`
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid white;
  padding: 8px ${gutter}px;
  padding-left: 0px;
  cursor: ${p => (p.onClick ? "pointer" : "normal")};
  margin-bottom: ${gutter}px;
  transition: box-shadow 0.3s ease;
  display: flex;
  align-items: center;

  div {
    margin-right: ${gutter}px;
  }

  svg {
    fill: white;
    margin-left: auto;
    transition: transform 0.3s ease;
    transform: rotate(${p => (p.open ? 180 : 0)}deg);
  }
`

const FaqItem = ({ data }) => {
  const headerRef = useRef()
  const contentRef = useRef()
  const { question = {}, answer = {}, answer_image, is_prio, anchorlink } = data
  const [answerWidth, setAnswerWidth] = useState()
  const [height, setHeight] = useState()

  const [open, setOpen] = useState(false)
  let isPrio = is_prio == 1
  let shouldScroll = false
  if (typeof window !== "undefined" && anchorlink && window.location.hash) {
    isPrio = window.location.hash.replace("#", "") === anchorlink
    shouldScroll = isPrio
  }

  useEffect(() => {
    if (shouldScroll) {
      const target = document.getElementById(anchorlink)
      if (target && target.scrollIntoView) {
        target.scrollIntoView({ behavior: "smooth" })
      }
    }
  }, [])

  const calculateHeight = once(() => {
    const bounds = contentRef.current.getBoundingClientRect()
    const h = bounds.height
    setHeight(h)
    return h
  })

  const onOpen = () => {
    setOpen(true)

    const newHeight = height || calculateHeight()

    contentRef.current.style.opacity = "1"
    contentRef.current.style.height = "0px"

    requestAnimationFrame(() => {
      contentRef.current.style.height = `${newHeight}px`
      contentRef.current.style.position = "relative"
    })
  }

  const onClose = () => {
    setOpen(false)

    contentRef.current.style.opacity = "0"
    contentRef.current.style.height = `0px`
  }

  useEffect(() => {
    setAnswerWidth(headerRef.current.getBoundingClientRect().width)
  }, headerRef)

  return (
    <Container id={anchorlink}>
      <Header
        open={open}
        ref={headerRef}
        onClick={
          isPrio
            ? undefined
            : () => {
                open ? onClose() : onOpen()
              }
        }
      >
        <Question dangerouslySetInnerHTML={{ __html: question.html }} />
        {!isPrio && icon}
      </Header>
      <Content isPrio={isPrio} open={open} ref={contentRef}>
        {answer.html && (
          <Answer
            dangerouslySetInnerHTML={{ __html: answer.html }}
            style={{ width: answerWidth }}
          />
        )}
        {answer_image.url && <Image image={answer_image} />}
      </Content>
    </Container>
  )
}

export default FaqItem
